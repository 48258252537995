import React from "react";
import { Flex } from "../../elements/Flex";
import { Heading } from "../../elements/Heading";
import { Text } from "../../elements/Text";
import { Ul } from "../../elements/Ul";
import { Li } from "../../elements/Li";
import { Link } from "gatsby";

const TermsOfServicePage = () => {
  return (
    <Flex
      py={4}
      px={3}
      width="100%"
      maxWidth="600px"
      flexDirection="column"
      margin="0 auto"
    >
      <Heading textAlign="center" mb={3}>
        Acceptable Use Policy
      </Heading>

      <Text mb={3}>
        <strong>What's in these terms?</strong>
      </Text>

      <Text mb={3}>
        Our Acceptable Use Policy sets out the content standards that apply when you
        upload content to our site, contact other users on our site, link to our site, or
        interact with our site in any other way.
      </Text>

      <Text mb={3}>
        By using our site, you confirm that you accept the terms of this policy and that
        you agree to comply with them.
      </Text>

      <Text mb={3}>
        We may amend this policy from time to time. Every time you wish to use our site,
        please check these terms to ensure you understand them.
      </Text>

      <Text mb={3}>
        <strong>General Terms of Use</strong>
      </Text>

      <Ul mb={3} ml={3}>
        <Li>You must create an account to use the content service.</Li>
        <Li>You must be at least 16 years old to use the service.</Li>
        <Li>
          You must use this website and the content service in accordance with our
          Acceptable Use Policy.
        </Li>
        <Li>You are responsible for keeping your password secret and secure.</Li>
        <Li>
          You agree that you will not solicit, collect, or use the login credentials of
          other users.
        </Li>
        <Li>
          You represent that all information you provide on this website upon registration
          and at all other times will be true, accurate, current, and complete and you
          agree to update your information as necessary to maintain its accuracy.
        </Li>
        <Li>
          You are responsible for any activity that occurs through your account and you
          agree you will not sell, transfer, license or assign your account, followers,
          username, or any account rights. With the exception of people or businesses that
          are expressly authorized to create accounts on behalf of their employers or
          clients, Lean Fitness Limited prohibits the creation of, and you agree that you
          will not create an account for, anyone other than yourself.
        </Li>
        <Li>
          Lean Fitness Limited does not claim ownership of any content you post on or
          through the Service. Instead, you hereby grant to Lean Fitness Limited a
          non-exclusive, fully paid and royalty-free, transferable, sub-licensable,
          worldwide license to use the Content that you post on or through the service,
          subject to our Privacy Policy.
        </Li>
        <Li>
          You acknowledge that we may not always identify paid services, sponsored
          content, or commercial communications as such.
        </Li>
        <Li>
          You represent and warrant that: (i) you own the content posted by you on or
          through the service or otherwise have the right to grant the rights and licences
          set forth in these terms; (ii) the posting and use of your content on or through
          the service does not violate, misappropriate or infringe on the rights of any
          third party, including, without limitation, privacy rights, publicity rights,
          copyrights, trademark and/or other intellectual property rights; (iii) you agree
          to pay for all royalties, fees, and any other monies owed by reason of content
          you post on or through the service; and (iv) you have the legal right and
          capacity to enter into these terms in your jurisdiction.
        </Li>
      </Ul>

      <Text mb={3}>
        <strong>Prohibited uses</strong>
      </Text>

      <Text mb={3}>
        You may use our site only for lawful purposes. You may not use our site:
      </Text>

      <Ul mb={3} ml={3}>
        <Li>
          In any way that breaches any applicable local, national or international law or
          regulation.
        </Li>
        <Li>
          In any way that is unlawful or fraudulent or has any unlawful or fraudulent
          purpose or effect.
        </Li>
        <Li>For the purpose of harming or attempting to harm minors in any way.</Li>
        <Li>To bully, insult, intimidate or humiliate any person.</Li>
        <Li>
          To knowingly transmit any data, send or upload any material that contains
          viruses, Trojan horses, worms, time-bombs, keystroke loggers, spyware, adware or
          any other harmful programs or similar computer code designed to adversely affect
          the operation of any computer software or hardware.
        </Li>
      </Ul>

      <Text mb={3}>You also agree:</Text>

      <Ul mb={3} ml={3}>
        <Li>Not to reproduce, duplicate, copy or re-sell any part of our site.</Li>
        <Li>Not to access without authority, interfere with, damage or disrupt:</Li>
        <Li>any part of our site;</Li>
        <Li>any equipment or network on which our site is stored;</Li>
        <Li>any software used in the provision of our site; or</Li>
        <Li>any equipment or network or software owned or used by any third party.</Li>
      </Ul>

      <Text mb={3}>
        <strong>Interactive services</strong>
      </Text>

      <Text mb={3}>
        We may from time to time provide interactive services on our site.
      </Text>

      <Text mb={3}>
        Where we provide any interactive service, we will provide clear information to you
        about the kind of service offered, if it is moderated and what form of moderation
        is used.
      </Text>

      <Text mb={3}>
        We will do our best to assess any possible risks for users (and in particular, for
        children) from third parties when they use any interactive service provided on our
        site, and we will decide in each case whether it is appropriate to use moderation
        of the relevant service (including what kind of moderation to use) in the light of
        those risks. However, we are under no obligation to oversee, monitor or moderate
        any interactive service we provide on our site, and we expressly exclude our
        liability for any loss or damage arising from the use of any interactive service
        by a user in contravention of our content standards, whether the service is
        moderated or not.
      </Text>

      <Text mb={3}>
        The use of any of our interactive services by a minor is subject to the consent of
        their parent or guardian. We advise parents who permit their children to use an
        interactive service that it is important that they communicate with their children
        about their safety online, as moderation is not foolproof. Minors who are using
        any interactive service should be made aware of the potential risks to them.
      </Text>

      <Text mb={3}>
        Where we do moderate an interactive service, we will normally provide you with a
        means of contacting the moderator, should a concern or difficulty arise.
      </Text>

      <Text mb={3}>
        <strong>Content Standards</strong>
      </Text>

      <Text mb={3}>
        <strong>Content Standards</strong>
      </Text>

      <Text mb={3}>
        These content standards apply to all material which you contribute to our site
        (Contribution), and to any interactive services associated with it. Our content
        standards must be complied with.
      </Text>
      <Text mb={3}>
        The standards apply to each part of any Contribution as well as to its whole.
      </Text>
      <Text mb={3}>
        Lean Fitness Limited will determine, in its discretion, whether a Contribution
        breaches the content standards.
      </Text>

      <Text mb={3}>
        <strong>A Contribution must:</strong>
      </Text>

      <Ul mb={3} ml={3}>
        <Li>Be accurate (where it states facts).</Li>
        <Li>Be genuinely held (where it states opinions).</Li>
        <Li>
          Comply with the law applicable in England and Wales and in any country from
          which it is posted.
        </Li>
      </Ul>

      <Text mb={3}>
        <strong>A Contribution must not:</strong>
      </Text>

      <Ul mb={3} ml={3}>
        <Li>
          Be defamatory of any person, be obscene, offensive, hateful, or inflammatory.
        </Li>
        <Li>Bully, insult, intimidate or humiliate, promote violence.</Li>
        <Li>
          Promote sexually explicit material or include child sexual abuse material.
        </Li>
        <Li>
          Promote discrimination based on race, sex, religion, nationality, disability,
          sexual orientation, or age.
        </Li>
        <Li>Infringe any copyright, database right or trade mark of any other person.</Li>
        <Li>Be likely to deceive any person.</Li>
        <Li>
          Breach any legal duty owed to a third party, such as a contractual duty or a
          duty of confidence.
        </Li>
        <Li>Promote any illegal content or activity.</Li>
        <Li>
          Be threatening, abuse or invade another's privacy, or cause annoyance,
          inconvenience or needless anxiety.
        </Li>
        <Li>Be likely to harass, upset, embarrass, alarm or annoy any other person.</Li>
        <Li>
          Impersonate any person or misrepresent your identity or affiliation with any
          person.
        </Li>
        <Li>
          Give the impression that the Contribution emanates from Lean , if this is not
          the case.
        </Li>
        <Li>
          You must not change, modify, adapt or alter the Service or change, modify or
          alter another website so as to falsely imply that it is associated with the
          Service or Lean Fitness Limited.
        </Li>
        <Li>
          Advocate, promote, incite any party to commit, or assist any unlawful or
          criminal act such as (by way of example only) copyright infringement or computer
          misuse.
        </Li>
        <Li>
          Contain a statement which you know or believe, or have reasonable grounds for
          believing, that members of the public to whom the statement is, or is to be,
          published are likely to understand as a direct or indirect encouragement or
          other inducement to the commission, preparation or instigation of acts of
          terrorism.
        </Li>
        <Li>
          Contain any advertising or promote any services or web links to other sites.
        </Li>
        <Li>
          You must not create or submit unwanted email, comments, likes or other forms of
          commercial or harassing communications (a/k/a “spam”) to any Lean Fitness
          Limited users.
        </Li>
        <Li>
          Any links shared on Lean Fitness Limited are your responsibility and we take no
          responsibility for any third party links shared on the Lean Fitness Limited
          website. If we find that any third-party links you have posted do not comply
          with our terms, you will be removed from the service.
        </Li>
      </Ul>

      <Text mb={3}>
        <strong>Breach of this policy</strong>
      </Text>

      <Text mb={3}>
        When we consider a breach of our Acceptable Use Policy has occurred, we may take
        such action as we deem appropriate.
      </Text>

      <Text mb={3}>
        Failure to comply with this Acceptable Use Policy constitutes a material breach of
        the terms of use upon which you are permitted to use our site, and may result in
        our taking all or any of the following actions:
      </Text>

      <Ul mb={3} ml={3}>
        <Li>
          Immediate, temporary or permanent withdrawal of your right to use our site.
        </Li>
        <Li>
          Legal proceedings against you for reimbursement of all costs on an indemnity
          basis (including, but not limited to, reasonable administrative and legal costs)
          resulting from the breach.
        </Li>
        <Li>Further legal action against you.</Li>
        <Li>
          Disclosure of such information to law enforcement authorities as we reasonably
          feel is necessary or as required by law.
        </Li>
      </Ul>

      <Text mb={3}>
        We exclude our liability for all action we may take in response to breaches of
        this acceptable use policy. The actions we may take are not limited to those
        described above, and we may take any other action we reasonably deem appropriate.
      </Text>

      <Text mb={3}>
        <strong>How this contract can be transferred</strong>
      </Text>

      <Text mb={3}>
        We can transfer our rights and obligations under these terms to any third party,
        provided this does not adversely affect your rights under these terms.
      </Text>

      <Text mb={3}>
        <strong>Which country's laws apply to any disputes?</strong>
      </Text>

      <Text mb={3}>
        If you are a consumer, please note that the terms of this policy, its subject
        matter and its formation are governed by English law. You and we both agree that
        the courts of England and Wales will have exclusive jurisdiction.
      </Text>
    </Flex>
  );
};

export default TermsOfServicePage;
